import axios from 'axios'

const el = document.getElementById('http-common')
let token: string = ''

if (el) token = el.dataset.value!

export const http = axios.create({
  baseURL: '/api/v4',
  headers: { Authorization: `Bearer ${token}` }
})

export const http5 = axios.create({
  baseURL: '/api/v5',
  headers: { Authorization: `Bearer ${token}` }
})

export const root = axios.create({
  baseURL: '/'
})

export const isNumber = (value?: string | number): boolean => {
  return ((value != null) && (value !== '') && !isNaN(Number(value.toString())))
}
